.modal-header {
  padding: 30px 0;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header button {
  border: 0;
  background: none;
  font: inherit;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}

.modal-content {
  padding: 20px 0 30px 0;
  display: flex;
  max-height: 100%;
  overflow-y: auto;
  flex-direction: column;
}

.modal-footer {
  display: flex;
  align-items: center;
  padding: 30px 0 20px 0;
  border-top: 1px solid var(--border-color);
}

@media only screen and (max-width: 800px) {
  .modal-inner-container {
    width: 100%;
  }
}
