body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  animation: fade-in 2s ease-out;
}

:root {
  --border-color: #e0e0e0;
  --title-color: #8e43d2;
  --background-color: #faf5ff;
  --positive-color: #109600;
  --negative-color: #cc3333;
}

#header-title {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  font-weight: 600;
  color: var(--title-color);
  text-shadow: 0 0 20px var(--title-color);
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--title-color);
  letter-spacing: 0.4rem;
  font-size: 1.2rem;
  text-transform: uppercase;
}

h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

p {
  line-height: 1.37;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(0.9);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(0.9);
  }
}

.loading-icon {
  height: min-content;
  font-size: 1.2rem;
  display: block;
  animation: spin 0.5s infinite;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 0.2s ease-out;
}

@keyframes fade-in-half {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
