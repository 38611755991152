:root {
  --input-border-color: #ccc;
  --label-color: #666;
}

.text-label {
  display: flex;
  flex-direction: column;
}

.text-label:not(:first-child) {
  margin-top: 15px;
}

.text-label > span {
  font-size: 0.85rem;
  color: var(--label-color);
  margin-bottom: 5px;
}

.text-input {
  height: 38px;
  padding: 0 10px;
  font: inherit;
  line-height: 38px;
  border-radius: 5px;
  border: 1px solid var(--input-border-color);
}

.textarea-input {
  height: 120px;
  padding: 10px;
  box-sizing: border-box;
  font: inherit;
  line-height: 1.37;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  resize: vertical;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-label:not(:first-child) {
  margin-top: 15px;
}

.checkbox-label span {
  margin-left: 5px;
  font-size: 0.85rem;
  user-select: none;
  color: var(--label-color);
}

.checkbox-label input {
  width: 15px;
  height: 15px;
}

.light-button {
  position: relative;
  height: 38px;
  white-space: pre;
  width: 100%;
  font: inherit;
  cursor: pointer;
  font-size: 0.85rem;
  padding: 0 10px;
  text-transform: uppercase;
  background-color: #ffffff;
  font-weight: 600;
  box-sizing: border-box;
  border: 2px solid var(--title-color);
  color: var(--title-color);
}

.light-button:focus::after,
.dark-button:focus::after {
  position: absolute;
  content: '';
  left: -5px;
  top: -5px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border: 3px solid #f3e6ff;
}

.dark-button {
  position: relative;
  height: 38px;
  white-space: pre;
  width: 100%;
  font: inherit;
  cursor: pointer;
  font-size: 0.85rem;
  padding: 0 10px;
  text-transform: uppercase;
  background-color: #894bc0;
  font-weight: 600;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  color: #ffffff;
}
